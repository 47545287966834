import { AndroidService } from './Android';

const WebviewService = {
  async performFraudChecks(...args) {
    const data = await callAndroidAsync('performFraudChecks', ...args);
    return data;
  },
};

export default WebviewService;

export async function callAndroidAsync(javaFuncName, params) {
  const rand =
    'asyncJava_' + javaFuncName + Math.floor(Math.random() * 1000000);
  window[rand] = {};

  // func called from android
  window[rand].callback = isSuccess => {
    const dataOrErr = AndroidService.runAsyncResult(rand);
    if (isSuccess) window[rand].resolve(dataOrErr);
    else window[rand].reject(dataOrErr);
    delete window[rand]; // clean up
  };

  AndroidService.runAsync(
    rand,
    javaFuncName,
    params ? JSON.stringify(params) : null
  );

  return new Promise((resolve, reject) => {
    window[rand].resolve = data => resolve(data);
    window[rand].reject = err => reject(err);
  });
}
