export class AppMeta {
  constructor(data) {
    this.installTime = data.installTime;
    this.name = data.name;
    this.versionName = data.versionName;
    this.versionNumber = data.versionNumber;
    this.pkgName = data.pkgName;
    this.signature = data.signature;
  }

  static from(obj) {
    if (!obj) return null;
    return new AppMeta({
      installTime: obj.app_install_time,
      name: obj.app_name,
      versionName: obj.app_version_name,
      versionNumber: obj.app_version_number,
      pkgName: obj.package_name,
      signature: obj.signature,
    });
  }
}
